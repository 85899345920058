const util = {}
/**
 * 数字加千分位
 * @param num
 * @returns {string}
 */
util.numWithComma=(num)=>{
    if (!num) return '0'
    let formattedValue = Number(num).toFixed(2)
    return  formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
/**
 * 数字去掉千分位
 * @param num
 * @returns {string}
 */
util.numWithOutComma=(num)=>{
    if (!num) return '0'
    return num.toString().replace(',',"");
}

util.numToChinese=(num)=>{
    if (!num) return '0'
    let flag=false;
    let number = Number(num);
    if (number<0){
        flag=true;
        number=-1*number
    }
    let formattedValue = number.toFixed(2)
    let strArr=formattedValue.split('.');
    let temp= Number(strArr[0]);
    let result='';
    if(temp>=10000){
        let temp2=Math.trunc(temp/10000);//亿
        let temp3=temp%10000;//万
        if(temp2>=10000){
            let temp4=Math.trunc(temp2/10000);
            let temp5=temp2%10000;
            result=temp4+"亿"+(temp5>0?temp5+"万":'')+(temp3>0?temp3:'')+"元"
        }else{
            result=temp2+"万"+(temp3>0?temp3:'')+"元"
        }
    }else{
        result =temp+"元";
    }
    if(strArr[1]!=='00'){
        result+=strArr[1].charAt(0)+"角"+strArr[1].charAt(1)+"分";
    }
    if (flag){
        return '-'+result;
    }else{
        return result;
    }
}
util.numToChinese2=(num)=>{
    if (!num) return '0'
    let flag=false;
    let number = Number(num);
    if (number<0){
        flag=true;
        number=-1*number
    }
    let formattedValue = number.toFixed(2)
    let strArr=formattedValue.split('.');
    let temp= Number(strArr[0]);
    let result='';
    if(temp>=10000){
        let temp2=Math.trunc(temp/10000);//亿
        let temp3=temp%10000;//万
        if(temp2>=10000){
            let temp4=Math.trunc(temp2/10000);
            let temp5=temp2%10000;
            result=temp4+"亿"+(temp5>0?temp5+"万":'')+(temp3>0?temp3:'')
        }else{
            result=temp2+"万"+(temp3>0?temp3:'')
        }
    }else{
        result =temp;
    }
    if(strArr[1]!=='00'){
        result+='.'+strArr[1];
    }
    if (flag){
        return '-'+result;
    }else{
        return result;
    }
}
export default util