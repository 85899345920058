import request from "./request";
import navData from "@/utils/navData";
import store from "@/store";
import {Message} from 'element-ui';
import platformUtil from "@/utils/platformUtil";

export async function getNavData() {
    let userNavData = [];
    if (store && store.state.nav.length > 0) {
        return store.state.nav;
    } else {
        let newData = Object.assign([], navData)
        await request.post('/webPowerList')
            .then(function (response) {
                if (response.data.code === 200) {
                    //构建导航数据
                    if (response.data.data.admin) {
                        //如果是管理员，赋予全部权限
                        userNavData = newData
                    } else {
                        //如果不是管理员，遍历，并且如果有子级，无父级，需要添加父级
                        userNavData = addNavDataToResult(newData, response.data.data.urlList)
                    }
                }
            })

        await store.dispatch('SETNAV', userNavData)
    }
    return userNavData;
}

function addNavDataToResult(newData, urlList) {
    let resultList = [];
    newData.forEach(item => {
        if (item.child) {
            let childResultList = addNavDataToResult(item.child, urlList);
            if (childResultList.length > 0) {
                item.child = childResultList;
                resultList.push(item);
            }
        } else {
            if (urlList.includes(item.url)) {
                resultList.push(item)
            }
        }
    })
    return resultList;
}

/**
 * 获取经验数值对象
 * @param name 名称
 * @param version 版本，传入0表示最新版本
 * @returns {Promise<{}>}
 */
export async function getEmpirical(name,version){
    let result = {};//TODO 待调整为缓存
    await request.post('/capp/basic/empiricalValue/getEmpirical', {
        name: name,
        version: version
    }).then(function (response) {
        if (response.data.code === 200) {
            result=response.data.data;
        }
    })
    return result;
}

export async function getEnum(parentTitle, title) {
    let enumData = [];
    if (store.state.enum[parentTitle] && store.state.enum[parentTitle][title] && store.state.enum[parentTitle][title].length > 0) {
        return store.state.enum[parentTitle][title];
    }
    await request.post('/enum/selectByTitle', {
        parentTitle: parentTitle,
        title: title
    })
        .then(function (response) {
            if (response.data.code === 200) {
                let list = response.data.data;
                if (!list || list.length === 0) {
                    return enumData;
                } else {
                    enumData = list.map(obj => {
                        return {
                            parentTitle: obj.parentTitle,
                            title: obj.title,
                            isSystem: obj.isSystem,
                            isEnable: obj.isEnable,
                            enumKey: obj.enumKey,
                            enumValue: obj.enumValue
                        }
                    });
                }
            }
        })
    let obj = {
        parentTitle: parentTitle,
        title: title,
        enumData: enumData
    }
    await store.dispatch('SetEnum', obj);
    return enumData;
}

/**
 * 查询文件夹列表[数据库]
 * @param parentFolder
 * @returns {Promise<*[]>}
 */
export async function folderTreeDB(parentFolder) {
    let result = [];
    await request.post("/file/folderTreeDB", {
        parentFolder: parentFolder
    }).then(function (response) {
        if (response.data.code === 200) {
            result.push(response.data.data);
        }
    })
    return result;
}

/**
 * 查询文件夹列表[服务器]
 * @param parentFolder
 * @returns {Promise<*[]>}
 */
export async function folderTreeServer(parentFolder) {
    let result = [];
    await request.post("/file/folderTreeServer", {
        parentFolder: parentFolder
    }).then(function (response) {
        if (response.data.code === 200) {
            result.push(response.data.data);
        }
    })
    return result;
}

/**
 * 获取树形结构数据
 * @param type   类型
 * @param parentNumber    父级
 * @returns {Promise<*[]>}
 */
export async function getTreeNodes(type, parentNumber) {
    let result = [];
    let url = '/tree/selectsTree'
    await request.post(url, {
        type: type,
        parentNumber: parentNumber
    }).then(function (response) {
        if (response.data.code === 200) {
            result.push(response.data.data);
        }
    })
    return result;
}

export async function getEnumTreeNodes() {
    let result = [];
    let url = '/enum/selectTree'
    await request.post(url,).then(function (response) {
        if (response.data.code === 200) {
            result.push(response.data.data);
        }
    })
    return result;
}
export async function getAccountGroupTreeNodes(company) {
    let result = [];
    let url = '/finance/accountGroup/selectsTree'
    await request.post(url,{
        company:company
    }).then(function (response) {
        if (response.data.code === 200) {
            result.push(response.data.data);
        }
    })
    return result;
}
export async function getCustomerGroupTreeNodes(company) {
    let result = [];
    let url = '/finance/customerGroup/selectsTree'
    await request.post(url,{
        company:company
    }).then(function (response) {
        if (response.data.code === 200) {
            result.push(response.data.data);
        }
    })
    return result;
}
/**
 * 下载文件
 * @param id
 */
export function download(id) {
    let url = '/file/download';
    request.post(url,
        {},
        {
            params: {
                id: id
            },
            responseType: 'blob',
        }
    ).then(response => {
        let fileNameValue = response.headers.get('content-disposition').split('filename=')[1];
        let fileName = decodeURIComponent(fileNameValue);
        const binaryData = [];
        binaryData.push(response.data)
        let blob = new Blob(binaryData, {'type': 'application/pdf'});
        let url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }).catch(err => {

    });
}

/**
 * 预览并打开PDF
 * @param id
 */
export function previewAndOpenPdf(id) {
    let url = '/file/previewPdf';
    request.post(url,
        {},
        {
            params: {
                id: id
            },
            responseType: 'blob',
        }
    ).then(response => {
        let fileNameValue = response.headers.get('content-disposition').split('filename=')[1];
        let fileName = decodeURIComponent(fileNameValue);
        const binaryData = [];
        binaryData.push(response.data)
        let blob = new Blob(binaryData, {'type': 'application/pdf'});
        let url = URL.createObjectURL(blob);
        window.open(url, fileName);
    }).catch(err => {
        Message.error("预览发生错误，不允许预览此文件");
    });
}

/**
 * 获取单个序号
 * @param type
 * @returns {string}
 */
export async function getSerial(type) {
    let result = '';
    await request.post('/numberingRule/getSerial', {
        type: type
    }).then(response => {
        if (response.data.code === 200) {
            result = response.data.data;
        }
    })
    return result;
}

/**
 * 获取多个序号
 * @param type
 * @param quantity
 * @returns {*[]}
 */
export async function getSerials(type, quantity) {
    let result = [];
    await request.post('/numberingRule/getSerials', {
        type: type,
        quantity: quantity
    }).then(response => {
        if (response.data.code === 200) {
            result = response.data.data;
        }
    })
    return result;
}

export async function getLayoutOptions(webKey, defaultTableFields) {
    //查缓存
    let filter2 = store.state.layout.filter(item=>item.webKey===webKey);
    if(filter2 && filter2.length===1){
        return filter2[0];
    }
    let result = {};
    let currentLayoutOption = {};
    await request.post("/config/webLayout/selectsByKey",
        {
            webKey: webKey,
        })
        .then(function (response) {
            if (response.data.code === 200) {
                let layoutOptions = response.data.data;
                if (layoutOptions) {
                    let userId = localStorage.getItem('userId')
                    let filter = layoutOptions.filter(item => item.isLocal === 1 || item.insertUserId.toString() === userId);
                    if (filter && filter.length === 1) {
                        currentLayoutOption = filter[0];
                        let arr = [];
                        result.tableFields = arr;
                        result.currentLayoutOption = currentLayoutOption
                        result.webKey=webKey;
                        currentLayoutOption.list.forEach(item => {
                            item.canEdit = item.canEdit === 1;
                            item.isShow = item.isShow === 1;
                            item.highLight=item.highLight===1;
                            let filter1 = defaultTableFields.filter(item2 => item2.fieldProp === item.fieldProp);
                            if (filter1 && filter1.length === 1) {
                                //默认数组中存在，则添加，并且删除默认数组中的值
                                defaultTableFields.splice(defaultTableFields.indexOf(filter1[0]), 1);
                                arr.push(item);
                            }
                        })
                        //处理逻辑：
                        //用户保存数组遍历的时候查找默认数组，如果查到了，表示此条正确，同时删掉默认数组中的元素
                        //如果查不到直接忽略，不再显示该字段
                        //如果遍历结束，默认数组中还有内容，表示这是新增加的字段，添加到结果中
                        if (defaultTableFields.length > 0) {
                            defaultTableFields.forEach(item=>{
                                arr.push(item);
                            })
                        }
                    }
                }
            }
        })
    await store.dispatch('SetLayout', result);
    return result;

}

export async function getPictureList(ids){
    let result = [];
    await request.post('/file/previewPicture', {
        ids:ids
    }).then(response => {
        if (response.data.code === 200 && response.data.data ) {
            let list=response.data.data;
            list.forEach(item=>{
                let imageUrl="data:"+item.contentType+";base64,"+item.data;
                result.push(imageUrl);
            })
        }
    })
    return result;
}
export async function getContract(queryModel){
    let result = [];
    await request.post('/purchase/contract/selects', queryModel).then(response => {
        if (response.data.code === 200 && response.data.data ) {
            result = response.data.data.list;
            if(result){
                result.forEach(item =>{
                    item.contractDate = platformUtil.shortDate(item.contractDate);
                })
            }
        }
    })
    return result;
}

export async function getAccountLinkInvoiceList(supplierName,organization){
    let result = {
        sum:0,
        list:[],
    };
    await request.post("/purchase/account/selectsInvoice",
        {
            supplierName: supplierName,
            organization: organization
        })
        .then(response=>{
            if (response.data.code === 200) {
                result.list = response.data.data;
                if (result.list && result.list.length > 0) {
                    result.list.forEach(item => {
                        result.sum += item.taxIncludedAmount;
                        if(item.date){
                            item.date = platformUtil.shortDate(item.date);
                        }
                    })
                }

            }
        })
    return result;
}

export async function getReportId(webKey){
    let result='';
    if(!webKey || webKey===''){
        Message.error("获取报表ID失败，必须传入webKey");
        return '';
    }
    await request.post("/report/jmReport/getReportId",
        {
            webKey:webKey
        })
        .then(function (response) {
            if (response.data.code === 200) {
                result=response.data.data;
            }
        })
    return result;
}
export async function getAccountAddModel(number,company) {
    let result = {};
    let url = '/finance/account/getAddModel'
    await request.post(url,{
        parentNumber:number,
        company:company
    }).then(function (response) {
        if (response.data.code === 200) {
            result=response.data.data;
        }
    })
    return result;
}

export async function getCustomerAddModel(groupNumber,company) {
    let result = {};
    let url = '/finance/customer/getAddModel'
    await request.post(url,{
        groupNumber:groupNumber,
        company:company
    }).then(function (response) {
        if (response.data.code === 200) {
            result=response.data.data;
        }
    })
    return result;
}
export async function canSync(syncType) {
    let result = true;
    if (syncType===''){
        Message.error("必须传入同步类型");
        return true;
    }
    let url = '/dataSync/canSync'
    await request.post(url,{
        syncType:syncType
    }).then(function (response) {
        if (response.data.code === 200) {
            result=response.data.data;
        }
    })
    return result;
}

export async function getGroupArr(groupType) {
    let result = [];
    let url = '/tree/selects'
    await request.post(url,{
        current: 1,
        pageSize: -1,
        fields: "",
        filters: {
            AND:[['type','=',groupType]]
        },
        orderBy: "",
    }).then(function (response) {
        if (response.data.code === 200) {
            result=response.data.data.list;
        }
    })
    return result;
}

export async function login(username,password) {
    let timestamp=Date.now();
    let timestampStr = timestamp.toString();
    let timestampArr=[];
    for (let i=0;i<timestampStr.length;i++){
        timestampArr.push(Number(timestampStr.charAt(i)));
    }
    timestampArr.reverse();
    let arr4=[];
    arr4.push(Number(timestampArr[0]*10+timestampArr[5]));
    arr4.push(Number(timestampArr[1]*10+timestampArr[6]));
    arr4.push(Number(timestampArr[2]*10+timestampArr[7]));
    arr4.push(Number(timestampArr[3]*10+timestampArr[8]));
    arr4.push(Number(timestampArr[4]*10+timestampArr[9]));
    let arr=[];
    let index=0;
    for (let i = 0; i < password.length; i++) {
        let charCode = password.charCodeAt(i);
        if (charCode>=33 && charCode<=126){
            let addNumber = charCode+arr4[index];
            if (i>0 && (i+1)%5===0){
                index=0;
            }else{
                index++;
            }
            if (addNumber>126){
                addNumber-=94;
            }
            arr.push(String.fromCharCode(addNumber));
        }else{
            //不处理
            arr.push(String.fromCharCode(charCode));
        }
    }
    //不足16位的补齐16位，无意义
    return await request.post('/login',{
        username:username,
        password:arr.join('')
    },{
        headers:{
            timestamp:timestamp
        }
    })
}


export async function saveReportModel(form) {

}