// 引入 axios
import axios from 'axios'
import { Message } from 'element-ui';

// 封装 baseURL
const request = axios.create({
    baseURL:"/api",//配合处理跨域问题
    // timeout:6000,//超时
});

//请求拦截器
request.interceptors.request.use(config => {
    //判断token是否存在
    if (localStorage.getItem('token')) {
        // 在请求头中添加token
        config.headers.Authorization = localStorage.getItem('token');
    }
    return config;
},)

request.interceptors.response.use(res=>{
    if(res.data.code !==200){
        let error='';
        if(res.data.code ===401){
            error='token已失效，请重新登录';
        }else{
            if(res.data.message && res.data.message !==''){
                error+=' '+res.data.message;
            }
            if(res.data.data ){
                if (res.data.data.message ){
                    error+=' '+res.data.data.message;
                }else{
                    error+=' '+res.data.data;
                }
            }
        }
        if (error.trim().length>0){
            Message.error(error.trim());
        }
    }
    return res
},err=>{
    Message.error("发生错误："+err);
    return Promise.reject(err)
})

export default request;