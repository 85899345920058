import numUtil from "@/utils/numUtil";
import dateUtil from "@/utils/dateUtil";
let platformUtil={...dateUtil,...numUtil}
import Vue from 'vue'

platformUtil.tableHeight=()=>{
    // let showHeader = localStorage.getItem('showHeader');
    return  window.innerHeight - 190;
    // if(showHeader && showHeader==="false"){
    //     return  window.innerHeight - 250;
    // }else{
    //     return  window.innerHeight - 250;
    // }
}

platformUtil.treeToArr=(treeData)=>{
    let resultArr=[];
    if(treeData.number===''){
        treeData.children.forEach(item=>{
            flattenTreeData(item,resultArr);
        })
    }else{
        flattenTreeData(treeData,resultArr);
    }
    return resultArr;
}

function flattenTreeData(tree,result){
    result.push(tree)
    if(tree.children){
        tree.children.forEach(item=>{
            flattenTreeData(item,result);
        })
    }
}

/**
 * 枚举取key值
 * @param enumArr 枚举数组
 * @param key 枚举的key或者value
 */
platformUtil.enumKeyToValue=(enumArr,key)=>{
    if(!enumArr || enumArr.length===0){
        return '';
    }
    let find1=enumArr.find(item=>item.enumKey===key);
    if(find1){
        return find1.enumValue;
    }
    let find2=enumArr.find(item=>item.enumValue===key);
    if(find2){
        return find2.enumValue;
    }
    return '';
}

/**
 * 枚举取value值
 * @param enumArr 枚举数组
 * @param key 枚举的key或者value
 */
platformUtil.enumValueToKey=(enumArr,key)=>{
    if(!enumArr || enumArr.length===0){
        return '';
    }
    let find1=enumArr.find(item=>item.enumKey===key);
    if(find1){
        return find1.enumKey;
    }
    let find2=enumArr.find(item=>item.enumValue===key);
    if(find2){
        return find2.enumKey;
    }
    return '';
}

platformUtil.getQueryModel=(query)=>{
    let queryModel = {
        current: 1,
        pageSize: 20,
        fields: "",
        filters: {
            AND:[]
        },
        orderBy: "",
    }
    if (query!==''){
        let split = query.trim().split(' ');
        if (split.length>1){
            split.forEach(item=>{
                if (item && item!==''){
                    queryModel.filters.AND.push({
                        LIKES:item
                    })
                }
            })
        }else{
            queryModel.filters.AND.push({
                LIKES:split[0]
            })
        }
    }
    return queryModel;
}
platformUtil.setQueryModel=(query,queryModel)=>{
    let split = query.trim().split(' ');
    if (queryModel.filters.AND){
        queryModel.filters.AND=[];
    }
    if (split.length>1){
        split.forEach(item=>{
            if (item && item!==''){
                queryModel.filters.AND.push({
                    LIKES:item
                })
            }
        })
    }else{
        queryModel.filters.AND.push({
            LIKES:split[0]
        })
    }
}
platformUtil.deepCopy=(obj)=>{
    return JSON.parse(JSON.stringify(obj));
}

/**
 * 构建搜索条件数组
 * @param filterArr 筛选条件
 * @param prefixArr 前置条件
 * @returns {*[]}
 */
platformUtil.buildFilter=(filterArr,prefixArr)=>{
    let errorAll = '';
    let arr = [];
    filterArr.forEach((item, index) => {
        let error = '';
        if (item.parenthesis === '') {
            if (item.value === '<?>' || item.value === '') {
                if (item.method.enumValue === '为空' || item.method.enumValue === '不为空') {
                    arr.push([item.field.fieldProp, item.method.enumKey, null, item.join]);
                } else {
                    error += '值不能为空；';
                }
            } else {
                if (item.value.indexOf("'") >= 0) {
                    error += '值中不可以有单引号；';
                } else {
                    arr.push([item.field.fieldProp, item.method.enumKey, item.value.trim(), item.join]);
                }
            }
        } else {
            if (item.parenthesis===')'){
                arr.push([item.parenthesis+' '+item.join]);
            }else{
                arr.push([item.parenthesis]);
            }
        }
        if (error !== '') {
            errorAll += ' 第' + (index + 1) + '行：' + error;
        }
    })
    if (errorAll.length > 0) {
        Vue.prototype.$message({
            message: errorAll,
            type: 'warning'
        });
        return [];
    }
    if (prefixArr && prefixArr.length>0){
        let i=0;
        for (i ;i<prefixArr.length;i++){
            arr.splice(i,0,prefixArr[i]);
        }
        arr.splice(i,0,['(']);
        arr.push([')'])
    }
    return arr;
}

platformUtil.buildK3cloudFilter=(filterArr,prefixArr)=>{
    let errorAll = '';
    let arr = [];
    filterArr.forEach((item, index) => {
        let error = '';
        if (item.parenthesis === '') {
            if (item.value === '<?>' || item.value === '') {
                if (item.method.enumValue === '为空' || item.method.enumValue === '不为空') {
                    arr.push([item.field.k3cloudField, item.method.enumKey, null, item.join]);
                } else {
                    error += '值不能为空；';
                }
            } else {
                if (item.value.indexOf("'") >= 0) {
                    error += '值中不可以有单引号；';
                } else {
                    arr.push([item.field.k3cloudField, item.method.enumKey, item.value, item.join]);
                }
            }
        } else {
            arr.push([item.parenthesis]);
        }
        if (error !== '') {
            errorAll += ' 第' + (index + 1) + '行：' + error;
        }
    })
    if (errorAll.length > 0) {
        Vue.prototype.$message({
            message: errorAll,
            type: 'warning'
        });
        return [];
    }
    if (prefixArr && prefixArr.length>0){
        let i=0;
        for (i ;i<prefixArr.length;i++){
            arr.splice(i,0,prefixArr[i]);
        }
        arr.splice(i,0,['(']);
        arr.push([')'])
    }
    return arr;
}
export default platformUtil