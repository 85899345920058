import Vue from 'vue'
import VueRouter from 'vue-router'
import {getNavData} from "@/api/http";
import navData from "@/utils/navData";
// import navData from "@/utils/navData"

Vue.use(VueRouter)

const routes = [
    //登录页
    {
        path: '/',
        redirect: '/login'
    },
    //登录页
    {
        path: '/login',
        name: 'login',
        component: () => import( '../views/login/index.vue'),
    },
    //布局页
    {
        path: '/home',
        name: 'home',
        component: () => import( '../views/home/index.vue'),
        children: [],
    },
    //预览页
    {
        path: '/preview',
        name: 'preview',
        component: () => import( '../views/preview/index.vue'),
        children: [],
    },
]

//动态生成导航数据
navData.forEach(navDataItem => {
    let rootItem = routes[2].children;
    let routerItem = {
        path: navDataItem.url,
        name: navDataItem.name,
        component: () => import('../views/home' + navDataItem.component + '.vue'),
        meta:navDataItem.meta,
        children: [],
    };
    if (navDataItem.child) {
        buildComponent(routerItem, navDataItem.child);
    }
    rootItem.push(routerItem);
});

//递归调用，无限级下钻
function buildComponent(routerItem, navDataItemChild) {
    let leftMargin = routerItem.leftMargin;
    navDataItemChild.forEach(itemChild => {
        let routerChildItem = {
            path: itemChild.url,
            name: itemChild.name,
            component: () => import('../views/home' + itemChild.component + '.vue'),
            meta:itemChild.meta,
            children: [],
        };
        if (itemChild.child) {
            buildComponent(routerChildItem, itemChild.child);
        }
        routerItem.children.push(routerChildItem)
    })
}

const router = new VueRouter({
    routes
})

//路由拦截
router.beforeEach(async (to, from, next) => {
    const token = localStorage.getItem('token');
    if (!token && to.path !== '/login') {
        // 重定向到登录页
        if (to.path === '/login' || to.path==='/preview'){
            next();
        }else{
            next('/login');
        }
    } else {
        next();
    }
})

function addDynamic(result) {
    result.forEach(item => {
        let aaa = {
            path: item.url,
            name: item.name,
            component: () => import('../views/home' + item.component + '.vue'),
            children: []
        }
        //当前只支持1层循环
        if (item.child) {
            item.child.forEach(itemChild => {
                aaa.children.push({
                    path: itemChild.url,
                    name: itemChild.name,
                    component: () => import('../views/home' + itemChild.component + '.vue'),
                    children: []
                })
            })
        }
        routes[2].children.push(aaa);
        return routes;
    })
}

export default router
