const dateUtil = {}
dateUtil.now=()=>{
    let currentDate = new Date();
    let formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
    return formattedDate;
}
dateUtil.formatDate=(date) =>{
    const year = date.getFullYear()
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    return `${year}-${month}-${day}`
}
dateUtil.shortDate=(dateStr)=> {
    if (!dateStr || dateStr===''){
        return ''
    }
    const date=new Date(dateStr);
    const year=date.getFullYear();
    const month=String(date.getMonth()+1).padStart(2,'0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
dateUtil.normalDate=(dateStr)=> {
    if (!dateStr || dateStr===''){
        return ''
    }
    const date=new Date(dateStr);
    const year=date.getFullYear();
    const month=String(date.getMonth()+1).padStart(2,'0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2,'0');
    const minute = String(date.getMinutes()).padStart(2,'0');
    const second = String(date.getSeconds()).padStart(2,'0');
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}
dateUtil.getYear=(dateStr)=>{
    const date=new Date(dateStr);
    const year=date.getFullYear();
    return year;
}
dateUtil.addDays=(date,days)=>{
    const date2=new Date(date);
    date2.setDate( date.getDate()+days)
    return date2;
}
dateUtil.pickerSingleOptions={
    shortcuts: [{
        text: '今天',
        onClick(picker) {
            picker.$emit('pick', new Date());
        }
    }, {
        text: '昨天',
        onClick(picker) {
            const date = new Date();
            date.setDate(date.getDate()-1)
            picker.$emit('pick', date);
        }
    }, {
        text: '一周前',
        onClick(picker) {
            const date = new Date();
            date.setDate(date.getDate()-7)
            picker.$emit('pick', date);
        }
    }, {
        text: '一周后',
        onClick(picker) {
            const date = new Date();
            date.setDate(date.getDate()+7)
            picker.$emit('pick', date);
        }
    }],
}

dateUtil.pickerBetweenOptions= {
    shortcuts: [{
        text: '今天',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
        }
    },{
        text: '最近一周',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setDate(end.getDate()-7)
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近一个月',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(end.getMonth()-1)
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近三个月',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(end.getMonth()-3)
            picker.$emit('pick', [start, end]);
        }
    },  {
        text: '本月',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(end.getMonth());
            start.setDate(1);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '上个月',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(end.getMonth()-1);
            start.setDate(1);
            end.setMonth(end.getMonth());
            end.setDate(0);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '本年',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(0);
            start.setDate(1);
            picker.$emit('pick', [start, end]);
        }
    },{
        text: '去年',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(0-12);
            start.setDate(1);
            end.setMonth(0)
            end.setDate(0)
            picker.$emit('pick', [start, end]);
        }
    }]
}
export default dateUtil