//导航和路由公共数据
//url属性必填，且不要修改，作为后端控制权限的依据
export default [
    {
        title:'首页',
        name: '首页',
        url: '/index',
        icon: 'el-icon-s-home',
        component:'/index/index'
    },
    // {
    //     title:'报告模板',
    //     name: '报告模板',
    //     url: '/preview/index'
    //     ,component:'/preview/index'
    // },
    {
        title:'报告管理',
        name: '报告管理',
        url: '/report',
        icon: 'el-icon-s-custom',
        component:'/report/index',
        child: [
            {title:'报告列表',name: '报告列表',url: '/report/reportList',component:'/report/reportList'},
            {title:'检验项目',name: '检验项目',url: '/report/reportProject',component:'/report/reportProject'},
        ]
    },
    {
        title:'用户管理',
        name: '用户管理',
        url: '/user',
        icon: 'el-icon-s-custom',
        component:'/user/index',
        child: [
            {title:'用户列表',name: '用户列表',url: '/user/list',component:'/user/list'},
            {title:'角色列表',name: '角色列表',url: '/user/role',component:'/user/role'},
            {title:'个人信息',name: '个人信息',url: '/user/current',component:'/user/current'},
        ]
    },
    {
        title:'系统管理',
        name: '系统管理',
        url: '/system',
        icon: 'el-icon-s-tools',
        component:'/system/index',
        child: [
            {title:'数据字典',name: '数据字典',url: '/system/enumList',component:'/system/enumList'},
            {title:'编号规则',name: '编号规则',url: '/system/numberingRule',component:'/system/numberingRule'},
            {
                title:'日志管理',
                name: '日志管理',
                url: '/system/log/index',
                component:'/system/log/index',
                child:[
                    {title:'登录日志',name: '登录日志',url: '/system/log/loginLog',component:'/system/log/loginLog'},
                ]
            },
        ]
    },
]