import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    //状态
    state: {
        nav: [],
        enum: [],
        layout:[],
        openTab: [],
        activeIndex: '',
        company:'',
    },
    //计算属性
    getters: {},
    //具体执行 改变state
    mutations: {
        SETNAV(state, data) {
            state.nav = data;
        },
        ClearNav(state) {
            state.nav = []
        },
        SetEnum(state, data) {
            let parentTile = data.parentTitle;
            let title = data.title;
            let enumData = data.enumData;
            if(state.enum.includes(parentTile)){
                state.enum[parentTile][title]=enumData;
            }else{
              //不存在
                let arr=[];
                arr[title]=enumData;
                state.enum[parentTile]=arr;
            }
        },
        ClearEnum(state) {
            state.enum = []
        },
        SetLayout(state, data) {
            let webKey=data.webKey;
            let filter = state.layout.filter(item=>item.webKey===webKey);
            if(filter && filter.length>0){
                filter[0]=data;
            }else{
                state.layout.push(data);
            }

        },
        ClearLayout(state) {
            state.layout = []
        },
        add_tabs (state, data) {
            let filter = this.state.openTab.filter(item=>item.path===data.path);
            if(!filter || filter.length===0){
                this.state.openTab.push(data)
            }
        },
        delete_tabs (state, route) {
            let index = 0
            for (let item of state.openTab) {
                if (item.path === route) {
                    break
                }
                index++
            }
            this.state.openTab.splice(index, 1)
        },
        clear_tabs (state) {
            this.state.openTab=[];
        },
        set_active_index (state, index) {
            this.state.activeIndex = index
        },
        set_company(state, data) {
            state.company = data;
        },
    },
    //调用入口 提交mutations
    actions: {
        SETNAV({commit}, data) {
            commit('SETNAV', data);
        },
        ClearNav({commit}) {
            commit('ClearNav');
        },
        SetEnum({commit}, data) {
            commit('SetEnum', data);
        },
        ClearEnum({commit}) {
            commit('ClearEnum');
        },
        SetLayout({commit}, data) {
            commit('SetLayout', data);
        },
        ClearLayout({commit}) {
            commit('ClearLayout');
        },
        set_company({commit}, data) {
            commit('set_company', data);
        },
    },
    modules: {}
})
